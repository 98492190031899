import { validateField, validateEmail, validatePassword } from '../../core/utils/validation';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { VALIDATION_ERRORS, ROLE_KEYS, DASHBOARDS_MAP } from '../../core/constants';

import { connect } from 'react-redux';
import { isObject } from 'lodash';
import { Avatar, Box, Slide, DialogContent, DialogTitle, Typography } from '@mui/material';
import { Button, Divider, TextField, Dialog, InputAdornment } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { AuthFormWrapper } from '../../components/Auth/AuthFormWrapper';

import { withTranslation } from '../../i18n';
import { compose } from 'recompose';

import { Router, Link } from '../../server/routes';
import api from '../../core/api';
import socket from '../../core/ws';
import actions from '../../core/store/actions/auth';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      errors: {
        email: null,
        password: null,
      },
      showPwd: false,
      welcomeDialogOpen: true,
    };
  }

  handleKeyPress = ({ key }) => {
    if (key === 'Enter') {
      this.handleLogin();
    }
  };
  handleWelcomeDialogClose = () => {
    this.setState({ welcomeDialogOpen: false });
  };

  handleInputChange = name => ev => {
    const {
      target: { value },
    } = ev;

    this.setState({
      [name]: value,
      errors: {
        ...this.state.errors,
        [name]: null,
      },
    });
  };

  handleInputBlur = name => () => {
    const value = this.state[name];
    const error = validateField(name, value);
    if (error) {
      this.setState({
        errors: {
          [name]: error,
        },
      });
    }
  };

  handleLogin = async () => {
    const { email, password } = this.state;
    const { dispatch, t } = this.props;
    if (!email.length || !password.length) return;
    const emailError = validateEmail(email);
    const pwdError = validatePassword(password);
    if (emailError || pwdError) {
      this.setState({
        errors: {
          ...(emailError && { email: emailError }),
          ...(pwdError && { password: pwdError }),
        },
      });
      return;
    }

    try {
      const { user } = await api.Auth.login({
        email,
        password,
      });
      if (user) {
        const {
          role: { code },
        } = user;
        dispatch(actions.setCurrentUser(user));

        if (user?.role?.code && user?.role?.code !== ROLE_KEYS.USER) {
          socket.emit('connected_agent', user);
        }

        Router.pushRoute(DASHBOARDS_MAP[code]);
      } else {
        this.setState({
          errors: {
            email: t(VALIDATION_ERRORS.UNAUTHORIZED),
            password: t(VALIDATION_ERRORS.UNAUTHORIZED),
          },
        });
      }
    } catch (err) {
      const errors = isObject(err?.error)
        ? {
            email: t(err.error?.email || ''),
            password: t(err.error?.password || ''),
          }
        : {
            email: t(VALIDATION_ERRORS.UNAUTHORIZED),
            password: t(VALIDATION_ERRORS.UNAUTHORIZED),
          };

      this.setState({
        errors,
      });
    }
  };

  toggleShowPwd = () => {
    this.setState({
      showPwd: !this.state.showPwd,
    });
  };

  render() {
    const { email, password, errors, showPwd, welcomeDialogOpen } = this.state;

    const { t } = this.props;

    return (
      (<React.Fragment>
        {!welcomeDialogOpen && (
          <AuthFormWrapper>
            <TextField
              sx={{ marginBottom: '10px' }}
              id="email"
              name="mail"
              error={errors?.email}
              helperText={errors?.email ? t(errors.email) : ' '}
              label={t('pages.auth.email_input.placeholder')}
              value={email}
              fullWidth
              variant="outlined"
              onChange={this.handleInputChange('email')}
              onBlur={this.handleInputBlur('email')}
              onKeyPress={this.handleKeyPress}
              slotProps={{
                htmlInput: {
                  sx: { fontWeight: '400' },
                },

                inputLabel: {
                  sx: { fontWeight: '400' },
                },

                formHelperText: {
                  sx: { fontSize: '11px' },
                }
              }} />

            <TextField
              sx={{ marginBottom: '10px' }}
              id="password"
              name="password"
              error={errors?.password}
              helperText={errors?.password ? t(errors.password) : ' '}
              value={password}
              type={showPwd ? 'text' : 'password'}
              label={t('pages.auth.pwd_input.placeholder')}
              fullWidth
              variant="outlined"
              onChange={this.handleInputChange('password')}
              onBlur={this.handleInputBlur('password')}
              onKeyPress={this.handleKeyPress}
              slotProps={{
                input: {
                  endAdornment: (
                    <InputAdornment
                      sx={{ cursor: 'pointer' }}
                      onClick={this.toggleShowPwd}
                      position="end"
                    >
                      {showPwd ? <VisibilityOffIcon /> : <VisibilityIcon />}
                    </InputAdornment>
                  ),
                },

                htmlInput: {
                  sx: { fontWeight: '400' },
                },

                inputLabel: {
                  sx: { fontWeight: '400' },
                },

                formHelperText: {
                  sx: { fontSize: '11px' },
                }
              }} />
            <Box sx={{
              pt: 9
            }}>
              <Button
                sx={{ minWidth: 300, textTransform: 'none', marginBottom: '10px' }}
                fullWidth
                variant="contained"
                onClick={this.handleLogin}
              >
                {t('pages.auth.login_btn_label')}
              </Button>
              <Link route="/auth/recovery">
                <Button fullWidth>{t('pages.auth.forgot_pwd_label')}</Button>
              </Link>
            </Box>
          </AuthFormWrapper>
        )}
        <Dialog
          open={welcomeDialogOpen}
          onClose={this.handleWelcomeDialogClose}
          fullScreen
          fullWidth
          TransitionComponent={Transition}
          PaperProps={{
            sx: {
              display: 'flex',
              width: '100%',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(/static/background.jpg)`,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: '50% 50%',
            },
          }}
        >
          <DialogContent
            sx={{
              maxHeight: {
                xs: '100%',
                md: 800,
              },
              maxWidth: {
                xs: 400,
                md: 600,
              },
              color: 'white',
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'column',
            }}
          >
            <Box
              sx={{
                pt: 8,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <Avatar
                variant="square"
                alt="logo"
                src="/static/as_logo.svg"
                sx={{ width: '55px', height: '50px' }}
              />
              <Typography sx={{ fontSize: '10px', color: 'white', fontWeight: 300 }}>
                {t('pages.auth.avior_swiss_ag')}
              </Typography>
            </Box>
            <Divider variant="middle" sx={{ borderColor: 'white', borderBottomWidth: 1 }} />
            <Typography
              component="h2"
              sx={{
                fontSize: '24px',
                textTransform: 'uppercase',
                fontWeight: 500,
              }}
            >
              {t('pages.auth.welcome_to_oriox')}
            </Typography>
            <Typography
              sx={{
                fontSize: '18px',
                textAlign: 'center',
                fontWeight: 300,
                minWidth: 320,
              }}
            >
              {t('pages.auth.welcome_subtext')}
            </Typography>

            <Divider variant="middle" sx={{ borderColor: 'white', borderBottomWidth: 1 }} />
            <Box>
              <img alt="logo" src="/static/oriox_logo_blue.svg" width="115" />
              <Typography sx={{ fontSize: '10px', color: 'white', fontWeight: 300 }}>
                {t('pages.auth.logo_subtext')}
              </Typography>
            </Box>
            <Button
              onClick={this.handleWelcomeDialogClose}
              variant="contained"
              sx={{ minWidth: 300, mb: 8 }}
            >
              {t('pages.auth.login_btn_label')}
            </Button>
          </DialogContent>
        </Dialog>
      </React.Fragment>)
    );
  }
}

Login.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

export default compose(
  withTranslation('common'),
  connect(({ auth }, ownProps) => ({
    ...ownProps,
    auth,
  })),
)(Login);
